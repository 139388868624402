import { configureStore } from "@reduxjs/toolkit";
import basketSlice from "./basketSlice";
import orderSlice from "./orderSlice";
import applicationUserSlice from "./applicationUserSlice";

export const store = configureStore({
    reducer: {
        basketStore: basketSlice,
        orderStore: orderSlice,
        applicationUser: applicationUserSlice
    }
});