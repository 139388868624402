import React, {useEffect, useState} from "react";
import * as styles from "./buttonStyles.module.scss";
import {Button, ButtonProps, Spinner} from "react-bootstrap";

interface SecondaryButtonProps extends ButtonProps {
    showSpinner?: boolean;
    isLoading?: boolean
}

export default function SecondaryAsyncButton(props: SecondaryButtonProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {showSpinner, ...buttonProps} = props;

    useEffect(() => {
        if (props.isLoading || props.isLoading === false) {
            setIsLoading(props.isLoading);
        }
    }, [props.isLoading]);

    const onClick = async (event: any) => {
        setIsLoading(true);
        try {
            await props.onClick?.(event);
        } finally {
            setIsLoading(false);
        }
    };

    return <Button
        {...buttonProps}
        onClick={onClick}
        className={`${props.className} ${styles.secondaryButton}`}
        disabled={isLoading || props.disabled}
    >
        {isLoading || props.showSpinner ? <div className={styles.childrenContainer}>
            {props.children}
            <Spinner
                className={styles.spinner}
                role="status"
                animation={"border"}
                size={"sm"}/>
        </div> : props.children}
    </Button>;
}
