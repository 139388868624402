import "./src/styles/global.scss"
import {AuthProvider} from "oidc-react";
import {Provider} from 'react-redux';
import React from "react";
import {createBrowserHistory} from "history";
import {userManager} from "./src/global-functions/init-usermanager";
import type {GatsbyBrowser} from "gatsby";
import {store} from "./src/redux/store";
import ApiContextProvider from "./src/contexts/apiContext";
import {ModalProvider} from "./src/hooks/ModalContext";
import {MiniBasketProvider} from "./src/hooks/miniBasket/miniBasketContext";

const history = createBrowserHistory();
export const onServiceWorkerUpdateReady = () => window.location.reload();

export const wrapPageElement: GatsbyBrowser["wrapRootElement"] = ({element}) => {
    return <AuthProvider
        autoSignIn={false}
        userManager={userManager}
        onSignIn={userData => {
            const fromState = (userData?.state as any)?.targetPath;
            const targetUrl = fromState ? fromState : "/";
            history.replace(targetUrl);
        }}
    >
        <ApiContextProvider>
            <Provider store={store}>
                <ModalProvider>
                    <MiniBasketProvider>
                        {element}
                    </MiniBasketProvider>
                </ModalProvider>
            </Provider>
        </ApiContextProvider>
    </AuthProvider>
}
