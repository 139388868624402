import { createSlice } from "@reduxjs/toolkit";
import {OrderDto} from "../openapi";

const initialState = {
    order: {} as OrderDto
};

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrder: (state, action) => {
            state.order = action.payload;
        }
    }
});

export const { setOrder } = orderSlice.actions;
export const getOrder = (state: any) => state.orderStore;
export default orderSlice.reducer;