const userManagerInit = require("oidc-react")

const authSettings = {
    authority: process.env.GATSBY_AUTHORITY_URL,
    clientId: "BeeftekaBackend.Web",
    redirectUri: process.env.GATSBY_APP_URL,
    silentRedirectUri: process.env.GATSBY_APP_URL,
    autoSignIn: false,
    scope: "openid BeeftekaBackend.WebAPI profile",
};

export const userManager = userManagerInit.initUserManager(authSettings);
