import React, {createContext, useContext, useState, useCallback, ReactNode} from 'react';
import {Offcanvas} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {BasketApi, BasketDto, BasketItemDto} from '../../openapi';
import {getBasket, setBasket} from '../../redux/basketSlice';
import CloseSVG from '../../svg/close.svg';
import BinSVG from '../../svg/bin.svg';
import * as styles from './useMiniBasket.module.scss';
import QuantitySelector from '../../components/quantity-selector/quantitySelector';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import {createProductPath} from '../../helpers/productsHelper';
import {ApiContext} from "../../contexts/apiContext";
import SecondaryAsyncButton from "../../components/button/secondaryAsyncButton";

interface MiniBasketContextType {
    onAddToBasket: (productPriceId: number, count: number, dontShowMiniBasket?: boolean) => Promise<void>;
    onRemoveFromBasket: (basketItemId: string, count: number) => Promise<void>;
}

const MiniBasketContext = createContext<MiniBasketContextType | null>(null);

export const MiniBasketProvider = ({children}: { children: ReactNode }) => {
    const apiService = useContext(ApiContext);
    const dispatch = useDispatch();
    const currentBasket: BasketDto = useSelector(getBasket).basket;
    const [showMiniBasket, setShowMiniBasket] = useState(false);
    const [newlyAddedProductId, setNewlyAddedProductId] = useState(0);

    const data = useStaticQuery(graphql`
        {
          cmsApi {
            allProducts {
              id
              localImages {
                childImageSharp {
                  gatsbyImageData
                }
                id
              }
              imagesUrls
              prices {
                unitStock
                id
              }
            }
          }
        }
    `);

    const onAddToBasket = useCallback(async (productPriceId: number, count: number, dontShowMiniBasket?: boolean) => {
        if (!apiService) return;
        try {
            const response = await apiService!.getApi(BasketApi)
                .apiBasketPost({
                    count: count,
                    productPriceId: productPriceId
                });

            setNewlyAddedProductId(productPriceId);

            dispatch(setBasket(response.data));

            if (!dontShowMiniBasket) {
                setShowMiniBasket(true);
            }
        } catch (e: any) {
            return e.response?.data;
        }
    }, [apiService]);

    const onRemoveFromBasket = useCallback(async (basketItemId: string, count: number) => {
        if (!apiService) return;

        try {
            const response = await apiService.getApi(BasketApi)
                .apiBasketRemoveFromBasketDelete({
                    count: count,
                    basketItemId: basketItemId
                });
            dispatch(setBasket(response.data));
        } catch (e: any) {
            return e.response?.data;
        }
    }, [apiService]);

    const miniBasketContent = (
        <Offcanvas
            show={showMiniBasket}
            placement={"end"}
            onHide={() => setTimeout(() => setShowMiniBasket(false), 100)}
        >
            {currentBasket && <>
                <div className={styles.headerContainer}>
                    <div className={styles.header}>
                        Twój koszyk
                    </div>
                    <CloseSVG
                        onClick={() => setShowMiniBasket(false)}
                        className={styles.closeSVG}
                    />
                </div>

                <div className={styles.basketItemsContainer}>
                    {currentBasket.items && [...currentBasket.items].sort((a, b) => {
                        if (a.productPriceId === newlyAddedProductId) return -1;
                        if (b.productPriceId === newlyAddedProductId) return 1;
                        return 0;
                    }).map((item: BasketItemDto) =>
                        <div
                            key={item.id!}
                            style={{boxShadow: item.productPriceId === newlyAddedProductId ? "1px 1px 11px 3px rgba(0, 0, 0, 0.25)" : "unset"}}
                            className={styles.item}
                        >
                            <GatsbyImage
                                alt={item.name!}
                                image={data.cmsApi.allProducts?.find((x: any) => x.id === item.productId)
                                    ?.localImages?.sort((a: any, b: any) => a.id.split("/").pop() - +b.id.split("/").pop())
                                    ?.[0].childImageSharp.gatsbyImageData
                                }
                                className={styles.itemImage}
                            />
                            <div style={{width: "100%"}}>
                                <Link className={styles.itemNameAndPrice} to={createProductPath(item.name!)}>
                                    <div>{item.name}</div>
                                </Link>
                                <div>Jednostka: <p className={styles.itemNameAndPrice}>{item.unit}</p></div>
                                <div style={{margin: "18px 0"}}>
                                    Cena: <p className={styles.itemNameAndPrice}>{item.price} zł</p>
                                </div>
                                <QuantitySelector
                                    max={data.cmsApi.allProducts?.prices?.find((x: any) => x.id === item.productId)?.unitStock ?? undefined}
                                    itemPrice={item.price}
                                    initialValue={item.count}
                                    buttonClassName={styles.quantityButton}
                                    onAdd={async () => await onAddToBasket(item.productPriceId!, 1)}
                                    onSubtract={async () => await onRemoveFromBasket(item.id!, 1)}
                                />
                            </div>
                            <div
                                className={styles.binContainer}
                                onClick={async () => await onRemoveFromBasket(item.id!, item.count!)}
                            >
                                <BinSVG className={styles.removeSvg}/>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.footerContainer}>
                    <div className={styles.divider}/>
                    <div className={styles.summaryContainer}>
                        <div className={styles.toPayText}>Do zapłaty</div>
                        <div className={styles.toPaySum}>
                            {currentBasket.items?.reduce((acc: number, curr: any) =>
                                acc + (curr.price * curr.count), 0).toFixed(2)} zł
                        </div>
                    </div>
                    <Link style={{pointerEvents: currentBasket.items?.length === 0 ? "none" : "unset"}}
                          to={'/basket/yourOrder'}
                    >
                        <SecondaryAsyncButton
                            className={styles.checkoutButton}
                            disabled={currentBasket.items?.length === 0}
                            onClick={() => setShowMiniBasket(false)}
                        >
                            Do kasy
                        </SecondaryAsyncButton>
                    </Link>
                    <SecondaryAsyncButton
                        className={styles.continueButton}
                        onClick={() => setTimeout(() => setShowMiniBasket(false), 100)}
                    >
                        Kontynuuj zakupy
                    </SecondaryAsyncButton>
                </div>
            </>}
        </Offcanvas>
    );

    return <MiniBasketContext.Provider value={{onAddToBasket, onRemoveFromBasket}}>
        {children}
        {miniBasketContent}
    </MiniBasketContext.Provider>
};

export const useMiniBasket = () => {
    const context = useContext(MiniBasketContext);
    if (!context) {
        throw new Error("useMiniBasket must be used within a MiniBasketProvider");
    }
    return context;
};