import axios, { AxiosError, AxiosResponse } from "axios";
import "reflect-metadata";
import { Configuration } from "../openapi";
import { BaseAPI } from "./base";
import {userManager} from "../global-functions/init-usermanager";

export default class ApiService {
    constructor() {
        axios.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                if (error.response?.status === 401) {
                    console.log("Twoja sesja wygasła, przekierowanie do ekranu logowania.");
                    userManager?.removeUser();
                } else if (error.response?.status === 403) {
                    console.log("Wygląda na to że nie masz dostępu do danego zasobu. Spróbuj zalogować się ponownie, a jeżeli to nie pomoże, skontaktuj się z administratorem.");
                } else if (error.response?.status !== 400) {
                    console.log("Wystąpił błąd. Jeżeli będzie się to powtarzać, skontaktuj się z administratorem.");
                }
                return Promise.reject(error);
            }
        );
        axios.defaults.withCredentials = true;
    }

    public getApi<T extends BaseAPI>(c: new (configuration?: Configuration, basePath?: string) => T): T {
        const apiUrl = process.env.GATSBY_API_URL;
        const session = sessionStorage.getItem(`oidc.user:${process.env.GATSBY_AUTHORITY_URL}:BeeftekaBackend.Web`);
        const accessToken = session ? JSON.parse(session)["access_token"] : null;

        return new c(new Configuration({ accessToken, baseOptions: { headers: { Authorization: `Bearer ${accessToken}` } } }), apiUrl);
    }
}
