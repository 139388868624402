// extracted by mini-css-extract-plugin
export var basketItemsContainer = "useMiniBasket-module--basketItemsContainer--34ffa";
export var binContainer = "useMiniBasket-module--binContainer--f8f5f";
export var checkoutButton = "useMiniBasket-module--checkoutButton--8223b";
export var closeSVG = "useMiniBasket-module--closeSVG--19319";
export var continueButton = "useMiniBasket-module--continueButton--67933";
export var deliveryContainer = "useMiniBasket-module--deliveryContainer--e7be8";
export var divider = "useMiniBasket-module--divider--cdc44";
export var footerContainer = "useMiniBasket-module--footerContainer--1e9cd";
export var header = "useMiniBasket-module--header--157a9";
export var headerContainer = "useMiniBasket-module--headerContainer--e4160";
export var headerLarge = "useMiniBasket-module--headerLarge--10ad9";
export var item = "useMiniBasket-module--item--85965";
export var itemImage = "useMiniBasket-module--itemImage--de7c8";
export var itemNameAndPrice = "useMiniBasket-module--itemNameAndPrice--572b9";
export var price = "useMiniBasket-module--price--336bd";
export var priceContainer = "useMiniBasket-module--priceContainer--d01b6";
export var quantityButton = "useMiniBasket-module--quantityButton--d552b";
export var removeSvg = "useMiniBasket-module--removeSvg--1c7a2";
export var summaryContainer = "useMiniBasket-module--summaryContainer--c4e4f";
export var toPaySum = "useMiniBasket-module--toPaySum--e8bce";
export var toPayText = "useMiniBasket-module--toPayText--ce5b1";
export var toast = "useMiniBasket-module--toast--5605f";