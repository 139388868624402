import React from "react";
import { createContext, ReactNode } from "react";
import ApiService from "../openapi/apiService";

const service = new ApiService();

export const ApiContext = createContext<ApiService | undefined>(undefined);

export default function ApiContextProvider(props: { children: ReactNode }) {
    return <ApiContext.Provider value={service}>
        {props.children}
    </ApiContext.Provider>;
}