import { createSlice } from "@reduxjs/toolkit";
import { BasketDto } from "../openapi";

const initialState = {
    basket: {} as BasketDto
};

const basketSlice = createSlice({
    name: 'basketSlice',
    initialState,
    reducers: {
        setBasket: (state, action) => {
            state.basket = action.payload;
        }
    }
});

export const { setBasket } = basketSlice.actions;
export const getBasket = (state: any) => state.basketStore;
export default basketSlice.reducer;