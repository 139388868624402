import React, { createContext, useContext, useState, ReactNode } from "react";

interface ModalContextProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    isOver18: boolean | null;
    setIsOver18: (value: boolean | null) => void;
    hasSeenModal: boolean;
    setHasSeenModal: (value: boolean) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModalContext must be used within a ModalProvider");
    }
    return context;
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isOver18, setIsOver18] = useState<boolean | null>(null);
    const [hasSeenModal, setHasSeenModal] = useState<boolean>(false);

    return (
        <ModalContext.Provider
            value={{ showModal, setShowModal, isOver18, setIsOver18, hasSeenModal, setHasSeenModal }}
        >
            {children}
        </ModalContext.Provider>
    );
};