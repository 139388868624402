exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-basket-delivery-and-payment-tsx": () => import("./../../../src/pages/basket/deliveryAndPayment.tsx" /* webpackChunkName: "component---src-pages-basket-delivery-and-payment-tsx" */),
  "component---src-pages-basket-order-summary-tsx": () => import("./../../../src/pages/basket/orderSummary.tsx" /* webpackChunkName: "component---src-pages-basket-order-summary-tsx" */),
  "component---src-pages-basket-your-order-tsx": () => import("./../../../src/pages/basket/yourOrder.tsx" /* webpackChunkName: "component---src-pages-basket-your-order-tsx" */),
  "component---src-pages-flip-book-index-tsx": () => import("./../../../src/pages/flipBook/index.tsx" /* webpackChunkName: "component---src-pages-flip-book-index-tsx" */),
  "component---src-pages-forgot-password-complete-index-tsx": () => import("./../../../src/pages/forgotPasswordComplete/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-complete-index-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgotPassword/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inpost-tsx": () => import("./../../../src/pages/inpost.tsx" /* webpackChunkName: "component---src-pages-inpost-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-order-status-index-tsx": () => import("./../../../src/pages/order/status/index.tsx" /* webpackChunkName: "component---src-pages-order-status-index-tsx" */),
  "component---src-pages-payment-and-delivery-index-tsx": () => import("./../../../src/pages/paymentAndDelivery/index.tsx" /* webpackChunkName: "component---src-pages-payment-and-delivery-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacyPolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-product-search-index-tsx": () => import("./../../../src/pages/productSearch/index.tsx" /* webpackChunkName: "component---src-pages-product-search-index-tsx" */),
  "component---src-pages-profile-change-password-index-tsx": () => import("./../../../src/pages/profile/changePassword/index.tsx" /* webpackChunkName: "component---src-pages-profile-change-password-index-tsx" */),
  "component---src-pages-profile-data-tsx": () => import("./../../../src/pages/profile/data.tsx" /* webpackChunkName: "component---src-pages-profile-data-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-my-addresses-tsx": () => import("./../../../src/pages/profile/myAddresses.tsx" /* webpackChunkName: "component---src-pages-profile-my-addresses-tsx" */),
  "component---src-pages-profile-order-history-index-tsx": () => import("./../../../src/pages/profile/orderHistory/index.tsx" /* webpackChunkName: "component---src-pages-profile-order-history-index-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-returns-and-complaints-index-tsx": () => import("./../../../src/pages/returnsAndComplaints/index.tsx" /* webpackChunkName: "component---src-pages-returns-and-complaints-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/termsAndConditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-templates-category-details-tsx": () => import("./../../../src/templates/categoryDetails.tsx" /* webpackChunkName: "component---src-templates-category-details-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/productDetails.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */)
}

