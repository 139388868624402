import React, {useEffect, useState} from "react";
import * as styles from "./quantitySelector.module.scss";
import {Button, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";

export default function QuantitySelector(props: quantitySelectorProps) {
    const [quantity, setQuantity] = useState<number>(props.initialValue ?? 1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(props.isLoading || false);
    }, [props.isLoading]);

    useEffect(() => {
        if (!props.max) return;
        if (quantity > props.max) {
            props.quantityChange?.(quantity - props.max);
            setQuantity(props.max);
        }
    }, [props.max, quantity]);

    return <div className={styles.wrapper}>
        <div className={styles.selector}>
            <Button
                disabled={props.isDisabled || isLoading}
                className={styles.button}
                onClick={async (e) => {
                    setIsLoading(true);

                    try {
                        e.preventDefault();
                        e.stopPropagation();
                        if (quantity > 1) {
                            setQuantity(quantity - 1);
                            props.quantityChange?.(quantity - 1);
                            await props.onSubtract?.();
                        }
                    } finally {
                        setIsLoading(false);
                    }
                }}
            >
                <div
                    className={`${styles.label} ${quantity === 1 && styles.disabledLabel} ${styles.subtractLabel} ${props.buttonClassName}`}
                >
                    _
                </div>
            </Button>
            <div className={styles.quantity}>
                {quantity}
            </div>
            <Button
                disabled={props.isDisabled || isLoading}
                className={styles.button}
                onClick={async (e) => {
                    setIsLoading(true);

                    try {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!props.max || props.max > quantity) {
                            setQuantity(quantity + 1);
                            props.quantityChange?.(quantity + 1);
                            await props.onAdd?.();
                        }
                    } finally {
                        setIsLoading(false);
                    }
                }}
            >
                {props.max && quantity === props.max ? <OverlayTrigger
                        placement={"top"}
                        delay={{show: 250, hide: 400}}
                        overlay={quantity === props.max ? <Tooltip>
                            Osiągnięto maksymalną ilość produktu
                        </Tooltip> : <></>}
                    >
                        <div className={`${styles.label} ${styles.disabledLabel} ${props.buttonClassName}`}>
                            +
                        </div>
                    </OverlayTrigger>
                    : <div className={`${styles.label} ${props.buttonClassName}`}>
                        +
                    </div>
                }
            </Button>
        </div>
        {isLoading ? <Spinner animation="border" style={{marginLeft: "10px"}}/>
            : props.itemPrice && <div className={styles.price}>
            {(quantity * (props.itemPrice ?? 0)).toFixed(2)} zł
        </div>}
    </div>;
}

interface quantitySelectorProps {
    buttonClassName?: string,
    initialValue?: number,
    itemPrice?: number,
    onSubtract?: () => Promise<any>,
    onAdd?: () => Promise<any>,
    max?: number,
    quantityChange?: (quantity: number) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
}